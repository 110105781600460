import { Monitoring } from "@/utilities/monitoring";
import { Directory, Filesystem } from '@capacitor/filesystem';
import { isPlatform } from "@ionic/vue";
import axios from 'axios';
import moment from "moment";
import apiClient from "./api";
import { Share } from "@capacitor/share";

// TODO: consolidate with other convertBlobToBase64 function
const convertBlobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
};

const downloadExcelFileCapacitor = async (response: any, filename: string) => {
    const blob = new Blob([response.data]);

    const base64Data = await convertBlobToBase64(blob);
    await Filesystem.writeFile({
        path: filename,
        data: base64Data,
        directory: Directory.Cache,
    }).then(() => {
        return Filesystem.getUri({
            directory: Directory.Cache,
            path: filename,
        });
    })
        .then((uriResult) => {
            // Needs to done this way because we miss iOS permissions (UIFileSharingEnabled?)
            return Share.share({
                title: filename,
                url: uriResult.uri,
            });
        });
};

const downloadExcelFileWeb = (response: any, filename: string) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
};

export const generateExcel = (organizationId: number, projectId: number, prefix = "aufnahmen", locale = "de") => {
    const filename = `${prefix}-export-${moment().format('YYYY_MM_DD_HHmmss')}`;
    // TODO: use AbortController when available with axios ^0.22.0
    const cancelToken = axios.CancelToken.source();
    const call = apiClient.post("/generate-excel", { organizationId, projectId, filename, locale }, { responseType: "blob", cancelToken: cancelToken.token })
        .then((response) => {
            //TODO: consolidate with useDownloadHelper, did not want to import the composable here
            if (isPlatform("ios") || isPlatform("ipad") || isPlatform("android")) {
                downloadExcelFileCapacitor(response, `${filename}.xlsx`);
            } else {
                downloadExcelFileWeb(response, `${filename}.xlsx`);
            }

        }).catch((error: any) => {
            Monitoring.chainError("Excel export failed", error);
        });
    return { call, abort: cancelToken.cancel };
};